<template>
  <div class="telegram-card px-2 py-2 justify-content-between">
    <q-header color="#fff" :centered="false" level="1" size="s">
      <i18n path="news.telegram-card-title" tag="b">
        <br>
        <br>
      </i18n>
    </q-header>
    <div class="d-flex w-100 flex-column mt-5 mt-lg-2">
      <img src="@/assets/images/site/icons/networks_icons/telegram_w.svg" alt="" class="mx-auto">
      <b-btn href="https://t.me/qubityblog" variant="primary" class="mx-auto mt-2 goto-btn" block>{{$t('news.telegram-card-btn')}}</b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelegramCard"
}
</script>

<style scoped lang="scss">
.telegram-card {
  background: linear-gradient(155deg, #2228B6 53%, #383dbc 53%);
  border-radius: 32px;

  .goto-btn {
    background: var(--theme-accent-dark)!important;
    border-radius: 32px;
    border: none!important;
  }
  .goto-btn:hover {
    box-shadow: var(--theme-accent-light) 0 6px 14px !important ;
  }
}
</style>
