

    import $request from "@/modules/request/"

    export default {

        dataSource: {
            current_page: 1,
            has_more_pages: false,
            last_page: 1,
            total_count: 0,
            items: []
        },

        categories: [],

        get() {
            $request.get("news.list", {cat: null, page: 1}).then( rsp => {
                this.categories = rsp.categories;
                Object.keys( rsp ).forEach( key => {
                    if( this.dataSource.hasOwnProperty( key ) ) {
                        this.dataSource[ key ] = rsp[key];
                    }
                });
            });
        }

    }
