<template>
  <b-container class="container-px-lg news-page">
    <b-col class="mt-3 p-0">
      <span style="color: gray">
        <router-link class="text-black" to="/">{{$t('crumbs.home')}}</router-link>
         / {{$t('crumbs.blog')}}
      </span>
    </b-col>
    <q-header :centered="false" class="my-2 name_text" size="xxl" level="1">
      <b class="news-page-title">
        {{$t('news.header-a')}}<br>{{$t('news.header-b')}}
        <span>
          <span class="position-relative">
            <span class="position-relative zindex-2">{{$t('news.header-c')}}</span><img alt="" class="circle-blog position-absolute zindex-0 top-left w-100" src="@/assets/images/site/vacancies_ellipsis.svg"></span>
          <span class="zindex-2">{{$t('news.header-d')}}</span>
        </span>
      </b>
    </q-header>
    <div class="row flex-column-reverse flex-lg-row">
      <div class="col-12 col-lg-8 col-xl-9">
        <transition-group v-if="news.dataSource && news.dataSource.items.length" class="row" name="news-list" tag="div">
          <div v-for="newsItem in news.dataSource.items" :key="newsItem.id"
               class="col-12 col-md-6 col-lg-4 mt-1 news-col">
            <blog-card
                :date="new Date(newsItem.timestamp * 1000)"
                :img="newsItem.media.urls.original"
                :slug="newsItem.slug"
                :timeToRead="newsItem.time_read"
                :timeToReadEn="newsItem.time_read_en"
                :title="newsItem.header"
                :titleEn="newsItem.header_en"
                :topics="newsItem.categories"
                :media_alt="newsItem.media_alt"
                :media_title="newsItem.media_title"
            />
          </div>
          <div
              class="d-lg-none d-block col-12 col-md-6 col-lg-8 mt-1 mt-lg-2 news-col"
              key="uniquie"
          >
            <TelegramCard/>
          </div>
        </transition-group>
        <q-header v-else size="l">
          <b>{{$t('news.empty-page-msg')}}</b>
        </q-header>
        <div v-if="pagesRange.length > 1" class="control d-flex mt-1">
          <div class="pagination ml-auto">
            <div v-for="(page, index) in pagesRange" :key="index" :class="parseInt(($route.query.page || 1)) === parseInt(page + 1) && 'active-page'"
                 class="pagination-page-btn" @click="setPage(page + 1)"><span class="m-auto">{{
                page + 1
              }}</span></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 col-xl-3 mb-2">
        <p class="category">{{$t('news.categories')}}</p>
        <div class="d-flex flex-column flex-md-row flex-md-wrap flex-lg-column justify-content-md-start">
          <router-link :class="!$route.query.cat && 'active-category'"
                       :to="'/news?page=1'"
                       class="type_category d-block mr-md-1">
            <img alt="" src="@/assets/images/site/icons/shape_blue.svg"/>
            {{$t('news.all')}}
          </router-link>
          <router-link v-for="(newsCat, index) in news.categories"
                       :key="index"
                       :class="$route.query.cat === newsCat.slug && 'active-category'"
                       :to="'/news?cat=' + newsCat.slug + '&page=1'"
                       class="type_category d-block mr-md-1">
            <img alt="" src="@/assets/images/site/icons/shape_blue.svg"/>
            {{ $i18n.locale === 'ru' ? newsCat.title : newsCat.title_en || newsCat.title }}
          </router-link>
        </div>
        <p class="category" style="margin-top: 25px !important">{{$t('news.latest-posts')}}</p>
        <div class="row" v-if="lastNews.dataSource">
          <div v-for="(newsItem, index) in lastNews.dataSource.items.slice(0, 4)"
               :key="index"
               class="col-12 col-md-6 col-lg-12">
            <small-news-card
                :date="new Date(newsItem.timestamp * 1000)"
                :img="newsItem.img"
                :slug="newsItem.slug"
                :time="newsItem.time_read"
                :timeEn="newsItem.time_read_en"
                :title="newsItem.header"
                :titleEn="newsItem.header_en"
                :topics="newsItem.categories"
                class="item_news"
            />
            <hr>
          </div>
          <b-col class="d-none d-lg-block col-12">
            <TelegramCard/>
          </b-col>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import BlogCard from "@/views/Main/main-design-v2/views/news/components/BlogCard";
import SmallNewsCard from "@/views/Main/main-design-v2/views/news/components/SmallNewsCard";
import news from "@/modules/contents/news"
import lastNews from "@/modules/contents/lastNews"
import TelegramCard from "@/views/Main/main-design-v2/views/news/components/TelegramCard";

export default {
  metaInfo() {
    const title = this.$t('title-pages.news');
    return {
      title: title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Свежие новости, публикации и полезная информация в мире криптовалюты'
        }
      ]
    }
  },
  components: {BlogCard, SmallNewsCard, TelegramCard},
  data() {
    return {
      news,
      lastNews
    }
  },
  computed: {
    pagesRange: function () {
      return Array.from(Array(this.news.dataSource.last_page).keys())
    }
  },
  watch: {
    "$route.query": function (query) {
      this.news.get(query.cat, query.page || 1)
    }
  },
  methods: {
    setPage(n) {
      this.$router.replace({query: {cat: this.$route.query.cat, page: n}})
    }
  },
  created() {
    const query = this.$route.query

    if (!query.page) {
      this.$router.replace({query: {page: 1, cat: query.cat}})
    }

    this.lastNews.get()
    this.news.get(query.cat, query.page || 1)
  },
}
</script>

<style lang="scss" scoped>
.name_text {
  padding-bottom: 15px;
}

.blog-ellipse {
  z-index: 1;
}

.blog-ellipse {
  img {
    position: absolute;
    transform: scale(1, 1);
    z-index: -1;
    width: 120%;
    right: -15px;
  }
}

.category {
  margin: 7px 0 !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}


.type_category {
  margin-bottom: 5px !important;
  padding-top: 5px;
  font-size: 18px;
  line-height: 20px;
  color: var(--text-primary-color);

  img {
    width: 16px;
    margin-right: 5px;
    margin-bottom: 4px;
  }
}

.active-category {
  color: #1A56FF;
}

.size_14 {
  font-size: 14px !important;
}

.time_news {
  color: gray;
  margin-left: 20px;
  font-size: 14px;
  line-height: 20px;
}

.news-col {
  transition: all .3s ease;
  margin-bottom: 1.5rem;
}

.pagination-page-btn {
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(169, 169, 169, 0.3);
  display: flex;
  margin-left: 4px;
  transition: all .3s ease;
  cursor: pointer;
}

.active-page {
  border-color: var(--theme-accent-light) !important;
  color: var(--theme-accent-light) !important;
}

@supports not (aspect-ratio: 1) {
  .pagination-page-btn::before {
    content: "";
    float: left;
    padding-bottom: calc(100% / (1 / 1));
  }
}

.news-page {
  min-height: 70vh;
  margin-top: 24px;
}

.circle-blog {
  left: 0;
  top: 0;
  width: 100%;
}
</style>

<style>
.news-list-enter, .news-list-leave-to {
  transition: all .3s ease;
  opacity: 0;
  transform: translateY(10vh);
}

.news-list-leave-active {
  position: absolute;
}

.news-list-move {
  transition: transform .5s ease;
}
</style>
