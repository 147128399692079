<template>
  <router-link :to="'/news/' + slug" class="card_news">
    <div class="cats">
      <p class="size_14 type_category blue m-0 p-0" v-for="(cat, index) in topics" :key="index">
        <img style="width: 8px; margin-right: 3px" :src=img alt="" />
        {{ $i18n.locale === 'ru' ? cat.title : cat.title_en || cat.title }}
      </p>
    </div>
    <p class="type_category">{{ $i18n.locale === 'ru' ? title : titleEn || title}}</p>
    <p>{{ date.toLocaleDateString($i18n.locale) }}<span class="time_news" v-if="time"><img src="@/assets/images/site/icons/time.svg" alt="" />{{  $i18n.locale === 'ru' ? time : timeEn || time }}</span></p>
  </router-link>
</template>

<script>
import img from '@/assets/images/site/icons/shape_blue.svg'

export default {
  name: "small-news-card",
  props: {
    slug: {type: String, required: true},
    img: {type: String, default: img},
    topics: {type: Array, default: ["Криптовалюта"]},
    title: {type: String, default: "Bitcoin Ta: вот почему BTC все еще находится под угрозой падения"},
    titleEn: {type: String, default: "Bitcoin Ta: вот почему BTC все еще находится под угрозой падения"},
    date: {type: Date, default: new Date(2020, 4, 3)},
    time: {type: String, default: "5 мин на прочтение"},
    timeEn: {type: String, default: "5 мин на прочтение"},
  }
}
</script>

<style lang="scss" scoped>
.blue{
  color: #1A56FF;
}

.type_category{
  margin-bottom: 5px !important;
  padding-top: 5px;
  font-size: 18px;
  line-height: 20px;

  img{
    width: 16px;
    margin-right: 5px;
    margin-bottom: 4px;
  }
}

.size_14{
  font-size: 14px !important;
}

.time_news{
  color: gray;
  margin-left: 20px;
  font-size: 14px;
  line-height: 20px;
  img{
    margin-bottom: 3px;
    margin-right: 3px;
  }
}

.card_news{
  border-bottom: 1px solid rgba(120, 120, 120, .1);
}
</style>
