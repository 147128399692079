<template>
  <router-link :to="'/news/' + slug" class="card_news d-flex flex-column h-100 w-100">
    <img :src="img" :alt="media_alt" :title="media_title" class="item_img w-100"/>
    <div class="news-cats">
      <div class="type_news m-0 p-0" v-for="(topic, index) in topics" :key="index"><img alt="" src="@/assets/images/site/icons/shape_blue.svg"/>{{ $i18n.locale == 'ru' ? topic.title : topic.title_en || topic.title }}</div>
    </div>
    <div class="text_news">{{ $i18n.locale === 'ru' ? title : titleEn || title }}</div>
    <div class="mt-auto pb-1 d-flex">
        <span class="mt-auto date-news">
          {{ date.toLocaleDateString($i18n.locale) }}
        </span>
      <span class="time_news text-right" v-if="timeToRead">
          <img alt="" src="@/assets/images/site/icons/time.svg"/>
          {{ $i18n.locale == 'ru' ? timeToRead : timeToReadEn || timeToRead }}
        </span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "blog-card",
  props: {
    img: {type: String, default: require('@/assets/images/site/blog_photo.png')},
    topics: {type: Array, default: ["Криптовалюта"]},
    title: {type: String, default: "Bitcoin Ta: вот почему BTC все еще находится под угрозой падения"},
    titleEn: {type: String, default: "Bitcoin Ta: Here's why BTC is still in danger of falling"},
    timeToRead: {type: String, default: null},
    timeToReadEn: {type: String, default: null},
    date: {type: Date, default: () => new Date(2021, 5, 25)},
    slug: {type: String, required: true},
    media_alt: {type: String},
    media_title: {type: String},
  }
}
</script>

<style lang="scss" scoped>
.card_news {
  border-bottom: 1px solid rgba(120, 120, 120, .1);
}

.item_img {
  border-radius: 32px;
  min-height: 220px;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.type_news {
  color: #1A56FF;
  font-size: 14px;
}

.type_news img {
  width: 8px;
  margin-right: 5px;
  margin-bottom: 4px;
}

.text_news {
  color: var(--text-primary-color);
  margin-top: 0px !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
}

.date-news {
  color: var(--text-primary-color);
  margin-right: 10px;
  font-size: 14px;
}

.time_news {
  color: gray;
  margin-left: auto;
  font-size: 14px;
  line-height: 0.5;
}

.time_news img {
  margin-bottom: 3px;
  margin-right: 3px;
}

.news-cats {
  margin-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
